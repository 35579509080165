<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 205px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
      <el-table-column prop="name" label="名称">
      </el-table-column>
        <el-table-column prop="address" label="操作" width="200">
          <template #header>
            <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary" icon="el-icon-office-building" @click="getAdminaddList(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
      title="地区新增删除"
      v-model="dialogVisible"
      width="60%">
       <el-table  height="calc(100vh - 255px)" :data="AdminListdata" style="width: 100%;">
         <el-table-column prop="id" label="ID" />
         <el-table-column prop="area_name" label="地址" />
         <el-table-column label="操作" width="150px">
            <template #header>
            <el-button size="mini"  @click="dialog = true" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
           <template #default="scope">
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleDatass(scope.row)"></el-button>
          </template>
        </el-table-column>
       </el-table>
      </el-dialog>

       <el-dialog
      title="新增地区"
      v-model="dialog"
      width="60%">
      <el-select clearable style="margin:0 15px" v-model="que.id" @clear="clearOption" multiple  filterable remote>
                <el-option
                    v-for="item in region"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    >
                </el-option>
              </el-select>
              <el-button size="mini" type="primary" @click="addDiqu">保存</el-button>
      </el-dialog>
<!--      <div style="margin-top: 20px">-->
<!--        <el-pagination-->
<!--            background-->
<!--            @size-change="handleSizeChange"-->
<!--            @current-change="handleCurrentChange"-->
<!--            :current-page="page"-->
<!--            :page-sizes="[10, 20, 30, 40,50]"-->
<!--            :page-size="limit"-->
<!--            layout="total, sizes, prev, pager, next, jumper"-->
<!--            :total="total"-->
<!--        >-->
<!--        </el-pagination>-->

<!--      </div>-->
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">

        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select style="width: 100%" v-model="ruleForm.role_id" placeholder="">
            <el-option
                v-for="item in modeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.id" key="1" label="密码" prop="">
          <el-input v-model="ruleForm.password" type="password" show-password></el-input>
        </el-form-item>
        <el-form-item v-else label="密码" key="2" prop="password">
          <el-input v-model="ruleForm.password" type="password" show-password></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.id" key="3" label="确认密码" prop="">
          <el-input v-model="ruleForm.password_confirmation" type="password" show-password></el-input>
        </el-form-item>
        <el-form-item v-else label="确认密码" key="4" prop="password_confirmation">
          <el-input v-model="ruleForm.password_confirmation" type="password" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/menuApi"//本页面接口
import {addDq,AdminaddDq} from "@/api/Api.js"
export default {
  name: 'brand',
  data(){
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.password_confirmation !== '') {
          this.$refs.ruleForm.validateField('password_confirmation');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return{
      dialogVisible:false,
      dialog:false,
      login:false,
      drawer:false,
      tableData:[],
      AdminListdata:[],
      page:1,
      limit:1000,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
        password:'',
        password_confirmation:'',
        role_id:'',
      },
      region:[],
      // id:[],
      modeArr:[
      ],
      rules:{
        password: [
          { required: true, message: '请填写', trigger: 'change' },
          { validator: validatePass, trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        password_confirmation: [
          { required: true, message: '请填写', trigger: 'change' },
          { validator: validatePass2, trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
        tip: [
          { required: true, message: '请填写提示', trigger: 'change' },
        ],
        role_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        // password_confirmation: [
        //   { required: true, message: '请填写', trigger: 'blue' },
        // ],
        // password: [
        //   { required: true, message: '请填写', trigger: 'blue' },
        // ],
      },
      que:{
        page:1,
        limit:10000,
        user_idd:'',
        id:[]
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
    // this.getAdminaddList()
    this.getaddDq()
  },
  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getregisterData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
        }).catch((err)=>{

        })
      axios.getroleData().then((res)=>{
        console.log(res)
        this.modeArr=res.data.data
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
        password:'',
        password_confirmation:'',
        role_id:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.role_id=row.role_id
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            this.login=true
            axios.postaddregister(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditregister(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelregister({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    // 获取城市地区列表数据
    async getaddDq(){
      const {data:res} = await AdminaddDq()
      this.region = res
    },
  // 管理员地区列表数据
  async getAdminaddList(row){
    this.que.user_idd = row.id
    this.dialogVisible = true
    this.getAdminlist()
  },
  //管理员地区列表数据
  async getAdminlist(){
    const {data:res} = await axios.AdminaddList(this.que)
    this.AdminListdata = res.data
  },
  // 新增地区
  async addDiqu(){
    const res = await axios.addListdata(this.que)
    // console.log(res);
    if(res.code === 200){
    this.$message.success(res.msg)
     this.dialog = false
     this.getAdminlist(this.que)
    }
   
  },
  // 删除地区
  async deleDatass(e){
    const ress = await this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
    if(ress === 'confirm'){
      const res = await axios.delListdata(e.id)
      if(res.code === 200){
      this.$message.success(res.msg)
      this.getAdminlist(this.que)
    }
    }
    
  }
  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>